import React, { useState, useEffect } from "react"
import { Link as RouteLink, useHistory, useRouteMatch } from "react-router-dom"
import { api } from "../utils/api"
import { Container, Box, Grid, Link } from "@material-ui/core"

import SuccessTickIcon from "assets/images/tick-blue.svg"

import Field from "ui-elements/Field"
import PasswordField from "ui-elements/PasswordField"
import Button from "ui-elements/Button"
import ErrorBox from "ui-elements/ErrorBox"
import { Card } from "ui-elements/Card"
import { Text, H3 } from "ui-elements/Typography"
import ConfirmationCode from "components/ResetConfirmationCode"

// API error messsages
const getErrorMessage = (code) => {
  if (code === "InvalidParameterException")
    return "Sorry we cannot recover your account. Please contact support."
  if (code === "CodeMismatchException")
    return "Invalid code, please check the code and try again."
  if (code === "InvalidPasswordException")
    return "Sorry we cannot reset your password. Please contact support."
  if (code === "LimitExceededException")
    return "Attempt limit exceeded, please try again after some time."
  return "Something went wrong with your request. Please try again in a few minutes or contact support."
}

const MAX_TEXTFIELD_LENGTH = 255

const updateSearchParams = (key, value) => {
  const params = new URLSearchParams(window.location.search)
  params.set(key, value)

  // update search param without reloading
  const path =
    window.location.origin + window.location.pathname + "?" + params.toString()

  window.history.pushState({ path }, "", path)
}

const Reset = () => {
  const [isLoading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  // EMAIL | CODE | PASSWORD | SUCCESS
  const [stage, setStage] = useState("EMAIL")

  // Recover form
  const [email, setEmail] = useState("")
  const [emailErrorMessage, setEmailErrorMessage] = useState("")

  // Reset form
  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState("")
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("")
  const [password2, setPassword2] = useState("")
  const [confirmErrorMessage, setConfirmErrorMessage] = useState("")

  const [code, setCode] = useState(0)
  const history = useHistory()
  const { path } = useRouteMatch()

  useEffect(() => {
    document.title = "Arria NLG/Reset"
  }, [])

  const handleFieldChange = ({ target: { value, name } }) => {
    setErrorMessage("")

    switch (name) {
      case "email":
        setEmail(value)
        return setEmailErrorMessage(
          !value
            ? "Please enter your email address"
            : !/.+@.+/.test(value)
            ? "Please enter your email address"
            : value.length > MAX_TEXTFIELD_LENGTH
            ? `Email address exceeds ${MAX_TEXTFIELD_LENGTH} characters`
            : false,
        )
      case "password":
        setPassword(value)
        setConfirmErrorMessage(
          !password2
            ? confirmErrorMessage
            : password2 !== value
            ? "Passwords do not match"
            : false,
        )
        return setPasswordErrorMessage(
          !value
            ? "Please enter a new password"
            : value.length < 8
            ? "Password should be at least 8 characters long"
            : !/[0-9]/.test(value)
            ? "Passwords must contain at least 1 number"
            : !/[A-Z]/.test(value)
            ? "Passwords must contain at least 1 uppercase character"
            : !/[a-z]/.test(value)
            ? "Passwords must contain at least 1 lowercase character"
            : !/^\S+$/.test(value)
            ? "Passwords cannot contain spaces"
            : value.length > MAX_TEXTFIELD_LENGTH
            ? `Password exceeds ${MAX_TEXTFIELD_LENGTH} characters`
            : false,
        )
      case "confirm":
        setPassword2(value)
        return setConfirmErrorMessage(
          !value
            ? "Please enter the password again"
            : value !== password
            ? "Passwords do not match"
            : false,
        )
      default:
        return console.error("invalid field.")
    }
  }

  const sendEmailWithCode = async (_) => {
    setLoading(true)
    const url = process.env.REACT_APP_IAM_URL + "/idm/user/password/forgot"
    const data = { email }

    const res = await api.post(url, data).catch((error) => {
      setErrorMessage(getErrorMessage(error.data?.code))
      setLoading(false)
    })

    if (res?.status !== 201) return

    setStage("CODE")
    setLoading(false)
  }

  const handleReset = async (_) => {
    setLoading(true)
    const url =
      process.env.REACT_APP_IAM_URL + "/idm/user/password/forgot/confim"

    const data = { email, password, confirmationCode: code }

    const res = await api.patch(url, data).catch((error) => {
      console.log(error, getErrorMessage(error.data?.code))
      return setErrorMessage(getErrorMessage(error.data?.code))
    })

    setLoading(false)
    if (res?.status === 201) {
      updateSearchParams("stage", "success")
      history.push(path + "?" + "stage=success")
      return res?.status === 201 && setStage("SUCCESS")
    }
  }

  const emailFormIsValid = (_) => {
    if (emailErrorMessage) return false

    if (!email) {
      handleFieldChange({ target: { value: email, name: "email" } })
      return false
    }

    return true
  }

  const passwordFormIsValid = (_) => {
    if (passwordErrorMessage || confirmErrorMessage) return

    if (!password || !password2) {
      handleFieldChange({ target: { value: password, name: "password" } })
      handleFieldChange({ target: { value: password2, name: "confirm" } })
      return false
    }

    return true
  }

  const submit = async (e) => {
    e?.nativeEvent instanceof Event && e.preventDefault()
    setLoading(true)

    if (!emailFormIsValid()) return

    if (stage === "EMAIL") {
      await sendEmailWithCode()
      return
    }

    if (!passwordFormIsValid()) return
    if (passwordErrorMessage || confirmErrorMessage) return
    handleReset()
  }

  const handleCodeSubmit = (code) => {
    setCode(code)
    setStage("PASSWORD")
    setLoading(false)
    return Promise.resolve()
  }

  const resetHeaderMsg =
    stage === "EMAIL"
      ? "Reset your password"
      : stage === "CODE"
      ? "Enter confirmation code"
      : stage === "PASSWORD"
      ? "Reset your password"
      : ""
  return (
    <Container maxWidth="sm">
      <Box m={20} />
      <H3 align="center" style={{ lineHeight: "41px" }}>
        {resetHeaderMsg}
      </H3>
      <Box maxWidth="365px" margin="auto">
        {!!errorMessage && (
          <ErrorBox>
            <Text component="span" light>
              {errorMessage || "There was an error recovering your account."}
            </Text>
          </ErrorBox>
        )}
        <Box m={5} />

        {stage === "EMAIL" && (
          <form onSubmit={submit}>
            <Grid container direction="column" spacing={2}>
              <Field
                xs={12}
                label="Email address"
                name="email"
                type="email"
                value={email}
                onChange={handleFieldChange}
                error={!!emailErrorMessage}
                helperText={emailErrorMessage}
                required
                autoFocus
              />
            </Grid>
            <Box m={2} />
            <Button type="submit" loading={isLoading} fullWidth>
              Continue
            </Button>
          </form>
        )}

        {stage === "CODE" && (
          <Box align="center">
            <ConfirmationCode email={email} onSubmit={handleCodeSubmit} />

            <Box m={5} />
            <Text align="center" light style={{ fontSize: 14 }}>
              If you are having trouble receiving the
              confirmation&nbsp;code&nbsp;email, please{" "}
              <Link href="https://support.arria.com/support/tickets/new">
                contact support.
              </Link>
            </Text>
          </Box>
        )}

        {stage === "PASSWORD" && (
          <form onSubmit={submit}>
            <Grid container direction="column" spacing={2}>
              <PasswordField
                xs={12}
                label="Password"
                name="password"
                value={password}
                onChange={handleFieldChange}
                error={!!passwordErrorMessage}
                helperText={passwordErrorMessage}
                showPassword={showPassword}
                onToggleShowPassword={() => setShowPassword(!showPassword)}
                required
                autoFocus
              />
              <PasswordField
                xs={12}
                label="Confirm Password"
                name="confirm"
                value={password2}
                onChange={handleFieldChange}
                error={!!confirmErrorMessage}
                helperText={confirmErrorMessage}
                showPassword={showPassword}
                onToggleShowPassword={() => setShowPassword(!showPassword)}
                required
              />
            </Grid>

            <Box m={6} />
            <Button type="submit" loading={isLoading} fullWidth>
              Update password
            </Button>
          </form>
        )}
        {stage === "SUCCESS" && (
          <>
            <Box textAlign="center">
              <img src={SuccessTickIcon} alt="Success" />
            </Box>
            <Box m={3} />
            <H3
              align="center"
              style={{
                lineHeight: "41px",
                width: "400px",
                marginLeft: "-16px",
              }}
            >
              Password successfully changed
            </H3>
            <Box m={10} />
            <Card maxWidth="400px" margin="auto">
              <Text
                align="center"
                light
                style={{ color: "var(--grey-darker)" }}
              >
                You can now go back and{" "}
                <Link component={RouteLink} to="/signin">
                  log in.
                </Link>
              </Text>
            </Card>
          </>
        )}
      </Box>
    </Container>
  )
}

export default Reset
