import React, { useState, useEffect } from "react"

import { differenceInDays } from "date-fns"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { Link as RouteLink } from "react-router-dom"
import Icon from "@material-ui/core/Icon"
import Divider from "@material-ui/core/Divider"

import {
  isEmpty,
  toTitleCase,
  unix2date,
  formattedPlanPrice,
} from "utils/helpers"
import { useSubscriptions, usePlans } from "utils/api"
import { CenteredProgress } from "ui-elements/progress"
import { Card } from "ui-elements/Card"
import { Button } from "ui-elements/Button"
import { Text, TextItalicBold } from "ui-elements/Typography"
import emptyIcon from "assets/images/empty.svg"
import biLogo from "assets/images/icons-bi-square-a.svg"
import excelLogo from "assets/images/icons-excel-square-a.svg"
import studioLogo from "assets/images/icons-studio-square-a.svg"

import contactIconLink from "assets/images/contact.svg"
import manageSubsIconLink from "assets/images/manage-subscription.svg"
import updatePaymentIconLink from "assets/images/update-payment.svg"
import addLicenseIconLink from "assets/images/add-license.svg"
import subscribeIconLink from "assets/images/subscribe.svg"
import resubscribeIconLink from "assets/images/resubscribe.svg"

export const Subscriptions = ({ fns }) => {
  let { subscriptions, licences, isLoading, error } = useSubscriptions()
  let { plans } = usePlans()

  useEffect(() => {
    document.title = "Arria NLG/Subscriptions"
  }, [])

  if (!isEmpty(plans) && !isEmpty(subscriptions)) {
    subscriptions = subscriptions.map((s) => ({
      ...s,
      ...plans.find((p) => p.id === s.planId),
    }))
  }

  if (isLoading) return <CenteredProgress />

  // Workaround to avoid showing error message when first logging in
  if (error?.status === 401) return <Box height="10rem" />

  if (error)
    return <Typography>An error occurred fetching subscriptions</Typography>

  if (
    isEmpty(subscriptions) &&
    Array.isArray(licences) &&
    !licences.includes("studio-app")
  )
    return (
      <>
        <Card
          height="240px"
          width="350px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <img alt="Icon showing empty state" src={emptyIcon} />
          <Box m={2} />
          <Text style={{ color: "var(--grey-dark)" }} light>
            You have no subscriptions
          </Text>
        </Card>
      </>
    )

  return (
    <>
      <Box m={9} />
      <Typography variant="h5">My subscriptions</Typography>

      <Box m={5} />
      <Grid container>
        <Grid item xs={12}>
          <Grid container justify="flex-start" spacing={6}>
            {subscriptions.map((s) => {
              return <Subscription key={s.subscriptionId} sub={s} fns={fns} />
            })}
            {!isEmpty(licences) &&
            Array.isArray(licences) &&
            licences.includes("studio-app") ? (
              <SubscriptionStudio fns={fns} />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

const SubscriptionStudio = ({ fns }) => {
  const classes = useStyles()
  const subscriptionIcons = {
    contactIcon: (
      <Icon>
        <img alt="edit" src={contactIconLink} style={{ marginBottom: "5px" }} />
      </Icon>
    ),
  }
  // const planName = toTitleCase(sub.planId?.replace("-", " ")).split(" ")
  return (
    <Grid item>
      <Card minHeight="100%" className={classes.subscription}>
        <Box minHeight={236}>
          <img src={studioLogo} alt="logo" className={classes.logo} />
          <Box style={{ margin: "41px 0" }} />
          <h3 className={classes.title}>
            <span role="presentation" className="name2">
              Arria NLG Studio
            </span>
          </h3>
          <Divider style={{ margin: "46px 0 0 0" }} />
        </Box>
        <Button
          className={classes.ctaFull}
          fullWidth
          size="small"
          style={{ marginBottom: 0 }}
          onClick={fns.goToSales}
          variant="text"
          startIcon={subscriptionIcons.contactIcon}
        >
          Contact us to manage
        </Button>
      </Card>
    </Grid>
  )
}

const Subscription = ({ sub, fns }) => {
  const classes = useStyles()
  const [inProgress, setInprogress] = useState(false)

  const cancelled = ["cancelled", "non_renewing"].includes(sub.status)
  const endDate = sub.billingEnd && unix2date(sub.billingEnd)
  const daysRemaining = differenceInDays(
    new Date(sub.trialEnd * 1000),
    new Date(),
  )

  const subscriptionIcons = {
    contactIcon: (
      <Icon>
        <img
          alt="contact us"
          src={contactIconLink}
          style={{ marginBottom: "5px" }}
        />
      </Icon>
    ),
    manageSubsIcon: (
      <Icon>
        <img
          alt="manage subscription"
          src={manageSubsIconLink}
          style={{ marginBottom: "5px" }}
        />
      </Icon>
    ),
    updatePaymentIcon: (
      <Icon>
        <img
          alt="update payment"
          src={updatePaymentIconLink}
          style={{ marginBottom: "5px" }}
        />
      </Icon>
    ),
    addLicenseIcon: (
      <Icon>
        <img
          alt="add license"
          src={addLicenseIconLink}
          style={{ marginBottom: "5px" }}
        />
      </Icon>
    ),
    subscribeIcon: (
      <Icon>
        <img
          alt="susbscribe"
          src={subscribeIconLink}
          style={{ marginBottom: "5px" }}
        />
      </Icon>
    ),
    resubscribeIcon: (
      <Icon>
        <img
          alt="resusbscribe"
          src={resubscribeIconLink}
          style={{ marginBottom: "5px" }}
        />
      </Icon>
    ),
  }

  const cancelPlan = () => {
    setInprogress(true)
    const req = fns.cancelSubscription(sub.subscriptionId)
    req?.then ? req.finally(() => setInprogress(false)) : setInprogress(false)
  }

  const resubscribe = () => {
    setInprogress(true)
    const req = fns.reactivateSubscription(sub.subscriptionId)
    req?.then ? req.finally(() => setInprogress(false)) : setInprogress(false)
  }

  const addLicenses = (ev) => {
    ev.preventDefault()
    fns.dialog.open({
      title: "Add more licenses",
      message: (
        <Text light component="span">
          Please contact our Sales team to add more licenses to your
          subscription.
        </Text>
      ),
      actions: [
        {
          label: "Cancel",
          onClick: () => {
            fns.dialog.close()
          },
          color: "default",
          variant: "outlined",
        },
        {
          label: "Contact Sales",
          onClick: () => {
            fns.dialog.close()
            fns.goToAddLicenses(sub?.metaData?.category)
          },
          variant: "contained",
          color: "primary",
          autoFocus: true,
        },
      ],
    })
  }

  const handler = () => {
    if (sub.metaData) {
      fns.goToPlans(sub.metaData?.category)
      return
    }

    // plan is probably archived
    const name = toTitleCase(sub.planId.replace(/-/g, " "))
    fns.dialog.open({
      title: "Change Plan",
      message: (
        <Text component="span" light>
          Currently subscribed to: <b>{name}</b>
        </Text>
      ),
      actions: [
        {
          label: "Choose a new plan",
          variant: "text",
          autoFocus: true,
          onClick: () => {
            fns.dialog.close()
            fns.goToPlans(sub.planId.split("-")[0])
          },
          style: {
            marginLeft: "8px",
          },
        },
        {
          label: "Cancel subscription",
          variant: "text",
          color: "secondary",
          onClick: () => {
            fns.dialog.close()
            fns.cancelSubscription(sub.subscriptionId)
          },
        },
        {
          label: "Contact support",
          variant: "text",
          onClick: fns.goToSales,
          color: "default",
        },
        {
          label: "Dismiss",
          onClick: fns?.dialog.close,
          variant: "text",
          color: "default",
        },
      ],
    })
  }

  const icon = { bi: biLogo, excel: excelLogo }[
    sub.metaData?.category || sub.planId.split("-")[0]
  ]
  return (
    <Grid item>
      <Card minHeight="100%" className={classes.subscription}>
        {cancelled && <div className={classes.status}>CANCELLED</div>}
        <Box minHeight={240}>
          {icon && <img src={icon} alt="logo" className={classes.logo} />}
          <h3 className={classes.title}>
            <span role="presentation" className="name1">
              {sub.name?.split(" ")[0] ??
                toTitleCase(sub.planId?.replace("-", " "))}
            </span>
            <span role="presentation" className="name2">
              {sub.name?.split(" ").slice(1).join(" ")}
            </span>
          </h3>
          <Typography variant="subtitle2" className={classes.price}>
            ${formattedPlanPrice(sub.planAmount)}{" "}
            {sub.periodUnit && `/ ${sub.periodUnit} / single user`}
          </Typography>
          <Box m={3} />
          <Divider style={{ margin: "25px 0" }} />
          {inProgress && <CenteredProgress cover />}
          {sub.status === "in_trial" && (
            <>
              <TextItalicBold style={{ color: "#494949" }}>
                Trial ends
                {daysRemaining === 0
                  ? " today"
                  : daysRemaining === 1
                  ? " tomorrow"
                  : " in " + daysRemaining + " days"}
              </TextItalicBold>
              <Box m={2} />
            </>
          )}
          {cancelled && (
            <TextItalicBold style={{ color: "#494949" }}>
              Subscription ends on {endDate}
            </TextItalicBold>
          )}
        </Box>

        <div className={classes.buttons}>
          {cancelled && (
            <>
              <Button
                size="small"
                variant="text"
                className={classes.iconBtn}
                onClick={cancelled ? resubscribe : cancelPlan}
                startIcon={
                  cancelled
                    ? subscriptionIcons.resubscribeIcon
                    : subscriptionIcons.updatePaymentIcon
                }
              >
                {cancelled ? "Resubscribe" : "Cancel Plan"}
              </Button>
            </>
          )}

          {["active", "in_trial"].includes(sub.status) && (
            <>
              <Button
                className={classes.iconBtn}
                fullWidth
                component={RouteLink}
                to="/account/billing"
                size="small"
                variant="text"
                startIcon={subscriptionIcons.updatePaymentIcon}
              >
                Update payment method
              </Button>
              <Button
                className={classes.iconBtn}
                fullWidth
                onClick={addLicenses}
                size="small"
                variant="text"
                startIcon={subscriptionIcons.addLicenseIcon}
              >
                Add licenses
              </Button>
              <Button
                className={classes.iconBtn}
                fullWidth
                onClick={handler}
                size="small"
                variant="text"
                startIcon={subscriptionIcons.manageSubsIcon}
              >
                Manage plan
              </Button>
            </>
          )}
        </div>
      </Card>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  cancelBtn: {
    marginLeft: -4,
    marginTop: theme.spacing(1),
    textTransform: "unset",
    textDecoration: "underline",
    fontWeight: 700,
  },
  subscription: {
    border: "none",
    background: "white",
    padding: theme.spacing(7),
    display: "flex",
    flexDirection: "column",
    borderRadius: "var(--spc)",
    position: "relative",
    width: 340,
  },
  top: { flex: 1 },
  title: {
    color: theme.palette.common.black,
    lineHeight: "1.5rem",
    fontSize: "16px",
    marginBottom: 0,
  },
  price: {
    fontWeight: 600,
    fontSize: theme.spacing(2.8),
    color: "#494949",
  },
  cta: {
    padding: theme.spacing(1, 2),
    fontWeight: 700,
    fontSize: 13,
    textTransform: "unset",
    border: `2px solid ${theme.palette.primary.main}`,
    minWidth: "max-content",
    width: "49%",
  },
  ctaFull: {
    padding: theme.spacing(1, 2),
    fontWeight: 700,
    fontSize: 13,
    textTransform: "unset",
    minWidth: "max-content",
    width: "100%",
    justifyContent: "flex-start",
  },
  ctas: {
    display: "flex",
    justifyContent: "space-between",
  },
  iconBtn: {
    padding: theme.spacing(1, 1),
    fontWeight: 700,
    fontSize: 13,
    textTransform: "unset",
    minWidth: "max-content",
    width: "100%",
    justifyContent: "flex-start",
    height: 30,
  },
  logo: {
    width: theme.spacing(11),
    height: theme.spacing(11),
    marginBottom: 5,
  },
  status: {
    backgroundColor: theme.palette.error.main,
    borderBottomLeftRadius: theme.spacing(4),
    borderTopLeftRadius: theme.spacing(4),
    color: theme.palette.common.white,
    fontWeight: "bold",
    padding: theme.spacing(2, 2, 2, 4),
    position: "absolute",
    right: 0,
    textAlign: "center",
    verticalAlign: "middle",
    fontSize: 13,
  },
}))
