import React, { useState, useEffect, useLayoutEffect, useRef } from "react"

import Box from "@material-ui/core/Box"
import Collapse from "@material-ui/core/Collapse"
import Alert from "@material-ui/lab/Alert"
import AlertTitle from "@material-ui/lab/AlertTitle"

import Button from "ui-elements/Button"
import { Text } from "ui-elements/Typography"
import { isEmpty } from "utils/helpers"

// API error messsages
const getErrorMessage = (code) => {
  if (code === "CodeMismatchException")
    return "Invalid code. Please check the code or request a new code."
  if (code === "NotAuthorizedException") return "Account is already confirmed."
  return "Something went wrong with your request. Please try again in a few minutes or contact support."
}

const id = (x) => x

// Pass negative `direction` to focus previous
const focusNth = (ev, direction = 1) => {
  const nextIdx = Number(ev.target.dataset.idx) + direction
  if (nextIdx < 0 || nextIdx > 5) return

  const nextEl = document.querySelector(`input[data-idx="${nextIdx}"]`)

  if (!nextEl) {
    console.warn("Parsing digit index failed. target was:", ev?.target, {
      direction,
      nextIdx,
      nextEl,
      sel: `input[data-idx="${nextIdx}"]`,
    })
    return
  }

  nextEl && nextEl.focus()
}

const ConfirmationCode = ({ email, onSubmit = id }) => {
  // IDLE | LOADING | SUCCESS | ERROR
  const [state, setState] = useState("IDLE")
  const [alert, setAlert] = useState({})
  const [inputEmpty, setInputEmpty] = useState(true)
  const codeForm = useRef()

  useEffect(() => {
    document.title = "Arria NLG/Reset Confirmation"
  }, [])

  const submit = () => {
    // Merge values from individual input elements into a single code
    const code = Array.from(codeForm.current.children).reduce(
      (a, el) => a + el.value,
      "",
    )

    if (!/^\d{6}$/.test(code)) {
      setAlert({
        severity: "warning",
        message: `
          Validation code consists only of digits.
          Please check the code and try again.`,
      })
      return
    }

    setState("LOADING")
    setAlert({})
    onSubmit(code, email).catch((error) => {
      setState("ERROR")
      setAlert({
        severity: "warning",
        title: "Could not validate your account",
        message: getErrorMessage(error?.data?.code) ?? error,
      })
    })
  }

  useLayoutEffect(() => {
    const els = codeForm.current.querySelectorAll("input")

    // TODO: extract this functionality into it's own component
    let listeners = []
    const handlePaste = (ev) => {
      ev.preventDefault()
      const clipboardContent = ev.clipboardData.getData("Text")
      if (/^\d+$/.test(clipboardContent)) {
        clipboardContent.split("").forEach((digit, idx) => {
          const el = codeForm.current.querySelector(`input[data-idx="${idx}"]`)
          if (!el) return
          el.value = digit
          digit && el.focus()
          const isEmpty = Array.from(els).some((el) => el.value === "")
          setInputEmpty(isEmpty)
        })
      }
    }

    const handleKeyDown = (ev) => {
      if (ev.key === "Enter") {
        submit()
        return
      }

      if (!ev.target.value && ev.key === "Backspace") {
        ev.target.value = ""
        focusNth(ev, -1)
        const isEmpty = Array.from(els).some((el) => el.value === "")
        setInputEmpty(isEmpty)
        return
      }

      // Number
      if (/\d/.test(ev.key)) {
        ev.preventDefault()
        ev.target.value = ev.key
        focusNth(ev)
        const isEmpty = Array.from(els).some((el) => el.value === "")
        setInputEmpty(isEmpty)
        return
      }

      // Number/letter
      if (/\w/.test(ev.key)) {
        ev.target.value = ""
        const isEmpty = Array.from(els).some((el) => el.value === "")
        setInputEmpty(isEmpty)
        return
      }
    }

    els.forEach((el) => {
      // Note: the equivalent `el.onKeyDown` doesn't work reliably
      el.addEventListener("keydown", handleKeyDown)
      el.addEventListener("paste", handlePaste)
      // Keep track of all the event listeners for convenient cleanup
      listeners.push({ keydown: handleKeyDown, paste: handlePaste })
    })

    return () =>
      els.forEach((el, idx) => {
        const { keydown, paste } = listeners[idx]
        el.removeEventListener("keydown", keydown)
        el.removeEventListener("paste", paste)
      })

    // ¯\_(ツ)_/¯
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Collapse in={!isEmpty(alert)}>
        <Alert variant="filled" severity={alert?.severity} align="left">
          <AlertTitle>{alert?.title}</AlertTitle>
          {alert?.message}
        </Alert>
      </Collapse>

      <Text light>
        Please enter the confirmation code sent to{" "}
        {email ? <b>{email}</b> : "your email"} to reset your password.
      </Text>

      <Box m={4} />

      <fieldset id="validation-code" ref={codeForm}>
        <input type="text" maxLength={1} pattern="\d" data-idx="0" autoFocus />
        <input type="text" maxLength={1} pattern="\d" data-idx="1" />
        <input type="text" maxLength={1} pattern="\d" data-idx="2" />
        <input type="text" maxLength={1} pattern="\d" data-idx="3" />
        <input type="text" maxLength={1} pattern="\d" data-idx="4" />
        <input type="text" maxLength={1} pattern="\d" data-idx="5" />
      </fieldset>
      <Box marginTop={9} width="330px">
        <Button
          onClick={submit}
          loading={state === "LOADING"}
          disabled={inputEmpty}
          fullWidth
        >
          Confirm
        </Button>
      </Box>
    </>
  )
}

export default ConfirmationCode
