import React, { useEffect } from "react"

import { CustomerDetailsForm } from "components/CustomerDetailsForm"
import { Text } from "ui-elements/Typography"

export const AccountDetails = ({ fns, notify, triggerLogOut }) => {
  useEffect(() => {
    document.title = "Arria NLG/Account Details"
  }, [])
  const showSupportDialog = () =>
    fns.dialog.open({
      title: "Change account details",
      message: (
        <Text light component="span">
          Please contact support to change your account details.
        </Text>
      ),
      actions: [
        {
          label: "Dismiss",
          onClick: fns?.dialog.close,

          color: "default",
          variant: "outlined",
        },
        {
          label: "Contact support",
          onClick: fns?.goToSupport,
          variant: "contained",
          color: "primary",
          autoFocus: true,
        },
      ],
    })
  return (
    <CustomerDetailsForm
      showDialog={showSupportDialog}
      notify={notify}
      triggerLogOut={triggerLogOut}
    />
  )
}
