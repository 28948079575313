import React, { useEffect } from "react"

import Box from "@material-ui/core/Box"
import Backdrop from "@material-ui/core/Backdrop"
import Modal from "@material-ui/core/Modal"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Link from "@material-ui/core/Link"

import { Invoices } from "components/Invoices"
import { Card } from "ui-elements/Card"
import { CardSummary } from "components/CardSummary"
import { Text, H4 } from "ui-elements/Typography"
import Button from "ui-elements/Button"
import { usePaymentDetails, updatePaymentMethod, useCustomer } from "utils/api"
import { useState } from "react"
import { PaymentMethod } from "components/PaymentMethod"

export const Billing = ({ url, continueFn, notify }) => {
  const [formVisible, setFormVisible] = useState(false)

  const { paymentDetails } = usePaymentDetails()
  const { customer } = useCustomer()
  const card = paymentDetails?.card

  useEffect(() => {
    document.title = "Arria NLG/Billing"
  }, [])

  const fns = {
    showUpdateForm: () => setFormVisible(true),
    handleClose: () => setFormVisible(false),
    updateCard: ({ id }) => {
      updatePaymentMethod(id)
        .then(() =>
          notify({
            severity: "success",
            message: "Payment method updated successfully.",
          }),
        )
        .catch(() =>
          notify({
            severity: "warning",
            message: "There was an issue updating your payment method",
          }),
        )
        .finally(() => setFormVisible(false))
    },
  }

  return (
    <>
      {/* only show if customer has a payments profile */}
      {customer && customer.customerId && (
        <Card>
          <Text>Payment Method:</Text>
          <Box maxWidth="300px">
            <CardSummary />
          </Box>
          <Box m={5} />
          <Button size="small" onClick={fns.showUpdateForm}>
            {card ? "Update" : "Add a card"}
          </Button>
          <Box m={5} />
          <Text light>
            For other payment options, or volume discounts, please{" "}
            <Link
              href="https://www.arria.com/contact/"
              target="_blank"
              rel="noopener noreferrer"
            >
              contact sales
            </Link>
            .
          </Text>
        </Card>
      )}
      <Box m={15} />
      <Invoices baseUrl={url} continueFn={continueFn} />
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={formVisible}
        onClose={fns.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        className="center-content"
      >
        <Card style={{ minWidth: 450, position: "relative" }}>
          <IconButton
            aria-label="close"
            style={{ position: "absolute", top: 5, right: 5 }}
            onClick={fns.handleClose}
          >
            <CloseIcon />
          </IconButton>
          <H4 align="center">
            {card ? "Update payment method" : "Add a card"}
          </H4>
          <PaymentMethod onSubmit={fns.updateCard} btnLabel="Update" />
        </Card>
      </Modal>
    </>
  )
}
