import React, { useState } from "react"
import { Link as RouteLink, useHistory, useRouteMatch } from "react-router-dom"

import Alert from "@material-ui/lab/Alert"
import AlertTitle from "@material-ui/lab/AlertTitle"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Collapse from "@material-ui/core/Collapse"
import Link from "@material-ui/core/Link"
import CircularProgress from "@material-ui/core/CircularProgress"

import ConfirmationCode from "components/ConfirmationCode"
import { H3, H4, Text } from "ui-elements/Typography"
import { Plans } from "components/Plans"
import { SignupForm } from "components/SignupForm"
import NotFound from "pages/NotFound"
import { getSearchParam, isEmpty } from "utils/helpers"
import { OrderSummary } from "components/OrderSummary"
import { createSubscription, usePlans } from "utils/api"
// import yellowTick from "assets/images/tick-yellow.svg"
import yellowTick from "assets/images/icons-tick-confirmation-bi.svg"
import greenTick from "assets/images/tick-green.svg"
import notes from "assets/images/notes.svg"
import tutorial from "assets/images/tutorial.svg"
import powerBiLogo from "assets/images/powerbi_logo.png"
import microStrategyLogo from "assets/images/microstrategy_logo.png"
import tibcoSpotFireLogo from "assets/images/tibco_spotfire_logo.png"
import quilkSenseLogo from "assets/images/qliksense_logo.png"
import tableauLogo from "assets/images/tableau_logo.png"
import excelLogo from "assets/images/excel_logo.png"

import { Card } from "ui-elements/Card"

const WHITELISTED_SOURCES = {
  "excel-plugin": {
    category: "excel",
    planId: "excel-plugin",
  },
  "power-bi-plugin": {
    category: "bi",
    planId: "",
  },
  "tableau-plugin": {
    category: "bi",
    planId: "",
  },
  "qlik-sense-plugin": {
    category: "bi",
    planId: "",
  },
  "microstrategy-plugin": {
    category: "bi",
    planId: "",
  },
  "tibco-spotfire-plugin": {
    category: "bi",
    planId: "",
  },
  "online-subscription": {},
}

const updateSearchParams = (key, value) => {
  const params = new URLSearchParams(window.location.search)
  params.set(key, value)

  // update search param without reloading
  const path =
    window.location.origin + window.location.pathname + "?" + params.toString()

  window.history.pushState({ path }, "", path)
}

const Signup = () => {
  const source = getSearchParam("source")
  const planId = getSearchParam("plan")
    ? getSearchParam("plan")
    : source === "excel-plugin"
    ? "excel-2002"
    : source === "power-bi-plugin" ||
      source === "tableau-plugin" ||
      source === "qlik-sense-plugin" ||
      source === "microstrategy-plugin"
    ? "bi-apps-1001"
    : null

  // FORM | CODE | EXCEL_DONE | SUMMARY | PLAN | SUCCESS
  const [stage, setStage] = useState("FORM")
  const [user, setUser] = useState(null)
  const [alert, setAlert] = useState({})
  const { plans } = usePlans(planId)
  const selectedPlan = plans?.find((p) => p.id === planId)

  let { path, url } = useRouteMatch()
  const history = useHistory()
  // Temproray url fix for a wrong url "power-bi-plugin?source=power-bi-plugin"
  if (source === "power-bi-plugin?source=power-bi-plugin") {
    history.push(`${url}?source=power-bi-plugin`)
  }

  const baseurl = process.env.REACT_APP_PLUGIN_DOWNLOAD_URL
  const getStartedBIBaseUrl = "https://docs.integrations.arria.com/BI"

  const downloadLinkAppSource =
    "https://appsource.microsoft.com/en-us/product/office/WA200002020?tab=Overview"

  const downloadLinkPowerBI = baseurl + "/files/PowerBI/add-in/latest"
  const getStartedLinkPowerBi =
    getStartedBIBaseUrl + "/PowerBI/en/arria-for-power-bi-quick-start.html"

  const downloadLinkTableau = baseurl + "/files/Tableau/add-in/latest"
  const getStartedLinkTableau =
    getStartedBIBaseUrl + "/Tableau/en/getting-started-arria-for-tableau.html"

  const downloadLinkQlikSense = baseurl + "/files/QlikSense/add-in/latest"
  const getStartedLinkQlikSense =
    getStartedBIBaseUrl +
    "/QlikSense/en/getting-started-arria-for-qlik-sense.html"

  const downloadLinkMicrostrategy =
    baseurl + "/files/MicroStrategy/add-in/latest"
  const getStartedLinkMicrostrategy =
    getStartedBIBaseUrl +
    "/MicroStrategy/en/getting-started-arria-for-microstrategy.html"

  const downloadTibcoSpotfire = baseurl + "/files/TIBCOSpotfire/add-in/latest"
  const getStartedLinkTibcoSpotfire =
    getStartedBIBaseUrl +
    "/TIBCOSpotfire/en/getting-started-arria-for-tibco-spotfire.html"

  const sourcePlans =
    source === "tableau-plugin"
      ? {
          planName: "Arria for Tableau",
          downloadLink: downloadLinkTableau,
          getStartedLink: getStartedLinkTableau,
          logoLink: tableauLogo,
          width: 200,
        }
      : source === "qlik-sense-plugin"
      ? {
          planName: "Arria for Qlik Sense",
          downloadLink: downloadLinkQlikSense,
          getStartedLink: getStartedLinkQlikSense,
          logoLink: quilkSenseLogo,
          width: 150,
        }
      : source === "microstrategy-plugin"
      ? {
          planName: "Arria for MicroStrategy",
          downloadLink: downloadLinkMicrostrategy,
          getStartedLink: getStartedLinkMicrostrategy,
          logoLink: microStrategyLogo,
          width: 225,
        }
      : source === "tibco-spotfire-plugin"
      ? {
          planName: "Arria for TIBCO Spotfire",
          downloadLink: downloadTibcoSpotfire,
          getStartedLink: getStartedLinkTibcoSpotfire,
          logoLink: tibcoSpotFireLogo,
          width: 225,
        }
      : source === "power-bi-plugin"
      ? {
          planName: "Arria for Power BI",
          downloadLink: downloadLinkPowerBI,
          getStartedLink: getStartedLinkPowerBi,
          logoLink: powerBiLogo,
          width: 200,
        }
      : {
          planName: "Arria for Excel",
          downloadLink: downloadLinkPowerBI,
          getStartedLink: getStartedLinkPowerBi,
          logoLink: excelLogo,
          width: 125,
        }

  if (!Object.keys(WHITELISTED_SOURCES).includes(source)) return <NotFound />

  const fns = {
    hideAlert: () => setAlert({}),
    handleFormSubmit: (data) => {
      fns.hideAlert()
      updateSearchParams("stage", "code")
      setUser(data)
      return setStage("CODE")
    },

    handleConfirmSubmit: () => {
      const nextStage = source === "excel-plugin" ? "EXCEL_DONE" : "BI_DONE"

      updateSearchParams("stage", nextStage.toLowerCase())

      // add the stage url to history to track in the header
      history.push(
        path + "?" + "source=" + source + "&stage=" + nextStage.toLowerCase(),
      )
      // Create a subscription after code verification
      if (
        source === "power-bi-plugin" ||
        source === "tableau-plugin" ||
        source === "qlik-sense-plugin" ||
        source === "microstrategy-plugin" ||
        source === "tibco-spotfire-plugin"
      ) {
        const selectedPlanId = planId ? planId : "bi-apps-1001"
        setStage("LOADING")
        createSubscription({
          company: user.companyName,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          planId: selectedPlanId,
        }).then(() => setStage(nextStage))
      }
      // set stage for excel immediately
      if (source === "excel-plugin") {
        if (planId) {
          setStage("LOADING")
          createSubscription({
            company: user.companyName,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            planId: planId,
          }).then(() => {
            setStage(nextStage)
          })
        } else {
          setStage(nextStage)
        }
      }
      return Promise.resolve()
    },

    handlePlanSelect: (pln) => {
      updateSearchParams("plan", pln)
      setStage("SUMMARY")
    },

    createSubscription: (pid, token, company = user.company) => {
      fns.hideAlert()
      return createSubscription({
        company,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        planId: pid,
        token,
      })
        .then(() => setStage("SUCCESS"))
        .catch((error) => {
          console.log("creating subscription errorred", error)
          setAlert({
            severity: "error",
            title: "Payment method wasn't setup",
            message:
              "There was an issue verifying your payment details. Please check that your card details are entered correctly.",
          })
        })
    },
  }

  const Shell = ({ children, ...rest }) => (
    <Container maxWidth="xs" align="center" {...rest}>
      <Box m={15} />
      <Collapse in={!isEmpty(alert)}>
        <Alert variant="filled" severity={alert?.severity} align="left">
          <AlertTitle>{alert?.title}</AlertTitle>
          {alert?.message}
        </Alert>
      </Collapse>
      {children}
    </Container>
  )

  if (stage === "FORM")
    return (
      <Shell maxWidth="sm">
        <SignupForm
          source={source}
          sourcePlan={sourcePlans}
          onSubmit={fns.handleFormSubmit}
        />
      </Shell>
    )

  if (stage === "CODE")
    return (
      <Shell>
        <ConfirmationCode
          email={user?.email}
          onSubmit={fns.handleConfirmSubmit}
        />
      </Shell>
    )

  if (stage === "LOADING") {
    return (
      <Shell>
        <CircularProgress />
      </Shell>
    )
  }

  if (["BI_DONE", "EXCEL_DONE"].includes(stage))
    return (
      <Shell>
        {stage === "EXCEL_DONE" ? (
          <>
            <img
              src={greenTick}
              alt="Tick icon indicating success"
              style={{ width: "120px" }}
            />
            <H3>You’re all set!</H3>
            <div className="bar excel" />
            <Box m={5} />
            <Card style={{ padding: "40px" }}>
              <Text style={{ fontSize: "16px" }}>
                You can now return to the <br />
                Arria for Excel add-in and sign in.
              </Text>

              <Box m={3} />

              <Text style={{ fontSize: "16px" }} light>
                You will receive a confirmation email in your inbox in the next
                few minutes.
              </Text>
              <Text display="inline" style={{ fontSize: "16px" }} light>
                Your subscription includes a <br /> {selectedPlan?.trialPeriod}
                -day trial period.
              </Text>
              <Box m={3} />
              <Text light style={{ fontSize: "16px" }}>
                If you haven't already,
              </Text>
              <Link href={downloadLinkAppSource} underline="always">
                download the add-in here.
              </Link>
              <Box m={3} />
              <Text light style={{ fontSize: "16px" }}>
                You can manage your subscription by going to{" "}
                <Link component={RouteLink} to="/signin" underline="always">
                  My Account
                </Link>{" "}
                at any time.
              </Text>
            </Card>
            <Box m={10} />
            <div className="bar excel" />
            <Box m={15} />
            <Card style={{ padding: "24px", position: "relative" }}>
              <img
                src={tutorial}
                alt="notes icon"
                style={{
                  width: "70px",
                  position: "absolute",
                  top: "-35px",
                  left: "110px",
                }}
              />
              <img
                src={notes}
                alt="notes icon"
                style={{
                  width: "70px",
                  position: "absolute",
                  top: "-35px",
                  right: "110px",
                }}
              />
              <Box m={10} />
              <H4>Getting Started</H4>
              <Box m={4} />
              <Text style={{ fontSize: "16px" }} light>
                We’ve compiled a few handy videos and instructional documents to
                help you get started.
              </Text>
              <Box m={3} />
              <Link
                fullWidth
                underline="always"
                target="_blank"
                href="https://docs.integrations.arria.com/Excel/en/464714-getting-started-with-arria-for-excel.html"
              >
                Get Started
              </Link>
            </Card>
            <Box m={15} />
          </>
        ) : (
          <>
            <img
              src={yellowTick}
              alt="Tick icon indicating success"
              style={{ width: "120px" }}
            />
            <H3>You’re all set!</H3>
            <div className="bar bi" />
            <Box m={5} />
            <Card style={{ padding: "40px" }}>
              <Text style={{ fontSize: "16px" }}>
                You can now return to the <br />
                {sourcePlans.planName} add-in <br />
                and sign in.
              </Text>
              <Box m={3} />
              <Text style={{ fontSize: "16px" }} light>
                You will receive a confirmation email in your inbox in the next
                few minutes.
              </Text>
              <Text display="inline" style={{ fontSize: "16px" }} light>
                Your subscription includes a <br />
                {selectedPlan?.trialPeriod}-day trial period.
              </Text>
              <Box m={3} />
              <Text light style={{ fontSize: "16px" }}>
                If you haven't already,
              </Text>
              <Link href={sourcePlans.downloadLink} underline="always">
                download the add-in here.
              </Link>
              <Box m={3} />
              <Text light style={{ fontSize: "16px" }}>
                You can manage your subscription by going to{" "}
                <Link component={RouteLink} to="/signin" underline="always">
                  My Account
                </Link>{" "}
                at any time.
              </Text>
            </Card>
            <Box m={10} />
            <div className="bar bi" />
            <Box m={15} />
            <Card style={{ padding: "24px", position: "relative" }}>
              <img
                src={tutorial}
                alt="notes icon"
                style={{
                  width: "70px",
                  position: "absolute",
                  top: "-35px",
                  left: "110px",
                }}
              />
              <img
                src={notes}
                alt="notes icon"
                style={{
                  width: "70px",
                  position: "absolute",
                  top: "-35px",
                  right: "110px",
                }}
              />
              <Box m={10} />
              <H4>Getting Started</H4>
              <Box m={4} />
              <Text style={{ fontSize: "16px" }} light>
                We’ve compiled a few handy videos and instructional documents to
                help you get started.
              </Text>
              <Box m={3} />
              <Link
                fullWidth
                underline="always"
                target="_blank"
                href={sourcePlans.getStartedLink}
              >
                Get Started
              </Link>
            </Card>
            <Box m={15} />
          </>
        )}
      </Shell>
    )

  if (stage === "SUMMARY") {
    return (
      <Shell align="left">
        <OrderSummary
          selectedPlanId={
            source === "excel-plugin" || source === "excel-plugin"
              ? null
              : planId
          }
          onSubmit={fns.createSubscription}
          user={user}
        />
      </Shell>
    )
  }

  if (stage === "PLAN")
    return (
      <Shell maxWidth="md" align="left">
        <H3 style={{ marginBottom: "5rem" }}>Choose a plan</H3>
        <Plans
          onSubmit={fns.handlePlanSelect}
          category={WHITELISTED_SOURCES[source].category}
        />
      </Shell>
    )

  return (
    <Shell>
      <Text>
        There was an issue securely setting up the account. Please try again or
        contact support.
      </Text>
    </Shell>
  )
}

export default Signup
