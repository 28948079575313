import React from "react"
import ReactDOM from "react-dom"
import "assets/styles/index.css"
import App from "./components/App"
import * as serviceWorker from "./serviceWorker"

// Bizible Initialize for API usage.
window["Bizible"] = window["Bizible"] || {
  _queue: [],
  Push: function (o, p) {
    this._queue.push({ type: o, data: p })
  },
}

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
