import React from "react"
import { Link as RouteLink } from "react-router-dom"

import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Link from "@material-ui/core/Link"
import notes from "assets/images/notes.svg"
import tutorial from "assets/images/tutorial.svg"
import { Card } from "ui-elements/Card"
import Button from "ui-elements/Button"
import { H3, H4, Text, TextItalic } from "ui-elements/Typography"
import yellowTick from "assets/images/icons-tick-confirmation-bi.svg"
import greenTick from "assets/images/tick-green.svg"
import { usePlans } from "utils/api"

export const AllSet = ({ status, selectedPlanId, closeFn }) => {
  const { plans } = usePlans(selectedPlanId)
  const selectedPlan = plans?.find((p) => p.id === selectedPlanId)
  const isExcel = ["excel-2000", "excel-2002"].includes(selectedPlanId)
  const baseurl = process.env.REACT_APP_PLUGIN_DOWNLOAD_URL

  const downloadLinkAFA = baseurl + "/files/Excel/ArriaforAccountants/latest"
  // const downloadLinkPowerBI =
  //  baseurl + "/pubs3.studio.arria.com/files/PowerBI/add-in/latest"
  const downloadLinkExcel =
    "https://appsource.microsoft.com/en-us/product/office/WA200002020?tab=Overview"

  return (
    <Container align="center" className="order-summary">
      <img
        src={isExcel ? greenTick : yellowTick}
        alt="Tick icon indicating success"
        style={{ width: "100px" }}
      />

      <H3 style={{ color: "#000000" }}>You’re all set!</H3>
      <Box style={{ padding: "16px" }}>
        <Text
          display="inline"
          style={{ fontSize: "16px", color: "#494949" }}
          light
        >
          You will receive a confirmation email in your inbox in the next few
          minutes.
        </Text>
        <Box m={2} />
        {isExcel ? (
          <>
            {status === "active" ? (
              <>
                <Text
                  display="inline"
                  style={{ fontSize: "16px", color: "#494949" }}
                  light
                >
                  Your subscription for{" "}
                </Text>
                <TextItalic
                  display="inline"
                  style={{
                    fontSize: "16px",
                    color: "#494949",
                    fontWeight: "400",
                  }}
                >
                  {selectedPlan?.name}
                </TextItalic>
                <Text
                  display="inline"
                  style={{ fontSize: "16px", color: "#494949" }}
                  light
                >
                  {" "}
                  has been activated.{" "}
                </Text>
              </>
            ) : (
              <>
                {" "}
                <Text
                  display="inline"
                  style={{ fontSize: "16px", color: "#494949" }}
                  light
                >
                  Your subscription includes a {selectedPlan?.trialPeriod}-day
                  trial period.
                </Text>
              </>
            )}
            <Box m={2} />
            <Text style={{ fontSize: "16px", color: "#494949" }}>
              To get started, please go to{" "}
              <span style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
                <Link
                  href={
                    selectedPlanId === "excel-2000"
                      ? downloadLinkAFA
                      : downloadLinkExcel
                  }
                  underline="always"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Microsoft AppSource
                </Link>
              </span>{" "}
              to download the add-in.
            </Text>
            <Box m={3} />

            <Box m={3} />
          </>
        ) : (
          <>
            {status === "active" ? (
              <>
                <Text
                  display="inline"
                  style={{ fontSize: "16px", color: "#494949" }}
                  light
                >
                  Your subscription for{" "}
                </Text>
                <TextItalic
                  display="inline"
                  style={{
                    fontSize: "16px",
                    color: "#494949",
                    fontWeight: "400",
                  }}
                >
                  {selectedPlan?.name}
                </TextItalic>
                <Text
                  display="inline"
                  style={{ fontSize: "16px", color: "#494949" }}
                  light
                >
                  {" "}
                  has been activated.{" "}
                </Text>
              </>
            ) : (
              <>
                {" "}
                <Text
                  display="inline"
                  style={{ fontSize: "16px", color: "#494949" }}
                  light
                >
                  Your subscription includes a {selectedPlan?.trialPeriod}-day
                  trial period.
                </Text>
              </>
            )}
            <Box m={2} />
            <Text style={{ fontSize: "16px", color: "#494949" }}>
              To get started, please{" "}
              <Link
                component={RouteLink}
                to="/account/downloads"
                underline="always"
                onClick={closeFn}
              >
                download the add-in
              </Link>
              {"."}
            </Text>
            <Box m={3} />
          </>
        )}
      </Box>
      <Box m={5} />
      {isExcel ? <div className="bar excel" /> : <div className="bar bi" />}
      <Box m={15} />
      <Card style={{ padding: "24px", position: "relative" }}>
        <img
          src={tutorial}
          alt="notes icon"
          style={{
            width: "70px",
            position: "absolute",
            top: "-35px",
            left: "110px",
          }}
        />
        <img
          src={notes}
          alt="notes icon"
          style={{
            width: "70px",
            position: "absolute",
            top: "-35px",
            right: "110px",
          }}
        />
        <Box m={10} />
        <H4>Getting Started</H4>
        <Box m={4} />
        <Text style={{ fontSize: "16px" }} light>
          We’ve compiled a few handy videos and instructional documents to help
          you get started.
        </Text>
        <Box m={3} />
        <Link
          fullWidth
          underline="always"
          target="_blank"
          href={
            isExcel
              ? "https://docs.integrations.arria.com/Excel/en/464714-getting-started-with-arria-for-excel.html"
              : "https://docs.integrations.arria.com/"
          }
        >
          Get Started
        </Link>
      </Card>
      <Box m={15} />
    </Container>
  )
}
