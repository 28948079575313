import React, { useState } from "react"
import { useLocation } from "react-router-dom"

import Box from "@material-ui/core/Box"
import ChevronRight from "@material-ui/icons/ChevronRight"
import CircularProgress from "@material-ui/core/CircularProgress"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import { makeStyles } from "@material-ui/core/styles"

import Button from "ui-elements/Button"
import { CenteredProgress } from "ui-elements/progress"
import { H4, Large, Text } from "ui-elements/Typography"
import { getSearchParam, isEmpty, formattedPlanPrice } from "utils/helpers"
import { usePlans, useSubscriptions } from "utils/api"
import powerBiLogo from "assets/images/icons-bi-square-a.svg"
import excelLogo from "assets/images/icons-excel-square-a.svg"

const descriptions = {
  "bi-apps-1001": (
    <>
      <Text light style={{ fontWeight: 400 }}>
        <span style={{ fontWeight: 700 }}>Get started!</span> Arria Apps deliver
        an out-of-the-box NLG experience. No scripting or coding required.
        Includes:
      </Text>
      <Box m={5} />

      <Text style={{ textTransform: "uppercase", fontSize: 12 }}>
        NLG ANALYTICS APPS:
      </Text>
      <ul className="plan-desc-list-bi">
        <li>Descriptive statistics</li>
        <li>Time-based variance</li>
        <li>Target-based variance</li>
        <li>Trend analysis</li>
        <li>Ranking analysis</li>
        <li>Anomalies</li>
        <li>Correlations</li>
      </ul>
      <Box m={4} />
      <Text style={{ textTransform: "uppercase", fontSize: 12 }}>
        NLG VISUAL APPS:
      </Text>
      <ul className="plan-desc-list-bi">
        <li>Pie chart</li>
        <li>Bar chart</li>
        <li>Line chart</li>
      </ul>
      <Box m={4} />
      <Text style={{ textTransform: "uppercase", fontSize: 12 }}>
        INCLUDES ARRIA ANSWERS:
      </Text>
      <Box m={4} />
      <Text light style={{ fontWeight: 400 }}>
        Arria Answers combines NLG and insight analytics with other natural
        language technologies (NLP, NLU and NLQ). This combination delivers
        multi-turn drill-down capability for enhanced conversational context.
      </Text>
      <Box m={4} />
      <Text style={{ textTransform: "uppercase", fontSize: 12 }}>
        VIEW CUSTOM PROJECTS:
      </Text>
      <Box m={3} />
      <Text light style={{ fontWeight: 400 }}>
        View and dynamically filter narrative generated by custom projects
        created using Arria NLG Studio.
      </Text>
      <Box m={7} />
      <Text light style={{ fontWeight: 400 }}>
        <span style={{ fontWeight: 700, fontStyle: "italic" }}>Note: </span> To
        access custom narratives, you or someone in your organization must have
        an Arria NLG Studio license and at least one Studio project published.
      </Text>
    </>
  ),
  "bi-apps-1003": (
    <>
      <Text light>
        <span style={{ fontWeight: 700 }}>NLG your way! </span> Get access to
        Arria Apps (at left) along with the ability to view any number of custom
        projects created by Arria, or your company.
      </Text>
      <Box m={5} />
      <Text light>
        Arria Apps are the the easiest way to introduce NLG to your organization
        and will help broaden BI adoption.
      </Text>
      <Box m={6} />
      <Text light>
        Arria Custom allows you to tailor the narratives based on your use case.
        This is made possible with Arria Studio.
      </Text>
      <Box m={6} />
      <Text style={{ textTransform: "uppercase", fontSize: 12 }}>
        DEFINITIONS:
      </Text>
      <Box m={1} />
      <Text light style={{ fontWeight: 400 }}>
        <span style={{ fontWeight: 700, fontStyle: "italic" }}>Viewers – </span>
        Users who view and dynamically interact with data analytic business
        intelligence platforms and other web applications.
      </Text>
      <Text light style={{ fontWeight: 400 }}>
        <span style={{ fontWeight: 700, fontStyle: "italic" }}>
          Creators –{" "}
        </span>{" "}
        Users who create/edit Arria Studio projects that generate narratives for
        Viewers to interact with.
      </Text>
    </>
  ),
  "bi-apps-1002": (
    <>
      <Text light>
        <span style={{ fontWeight: 700 }}>NLG your way! </span> Get access to
        Arria Apps and Arria Answers (see Standard Arria for BI) along with the
        ability to develop custom projects using Arria NLG Studio.
      </Text>
      <Box m={4} />
      <Text light>
        Arria Apps are the easiest way to introduce NLG to your organization and
        will help broaden BI adoption.
      </Text>
      <Box m={4} />
      <Text light>
        Arria Custom allows you to tailor the narratives based on your use case.
        This is made possible with Arria NLG Studio.
      </Text>
      <Box m={4} />
      <Text style={{ textTransform: "uppercase", fontSize: 12 }}>USERS:</Text>
      <Box m={3} />
      <ul className="plan-desc-list-bi">
        <li>
          <Text light style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 700, fontStyle: "italic" }}>
              Viewers -{" "}
            </span>{" "}
            Users who view and dynamically interact with business intelligence
            platforms and other web applications.
          </Text>
        </li>
        <Box m={2} />
        <li>
          <Text light style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 700, fontStyle: "italic" }}>
              Creators -{" "}
            </span>{" "}
            Users who create/edit Arria NLG Studio projects that generate
            narratives for Viewers to interact with.
          </Text>
        </li>
      </ul>
      <Box m={7} />
      <Text light style={{ fontWeight: 400 }}>
        <span style={{ fontWeight: 700, fontStyle: "italic" }}>Note: </span> To
        access custom narratives, you or someone in your organization must have
        an Arria NLG Studio license and at least one Studio project published.
      </Text>
    </>
  ),
  "excel-2004": (
    <>
      <Box m={2} />
      <Text light>
        Bring an advanced NLG experience to Microsoft Excel users in your
        enterprise.
      </Text>
      <Box m={4} />
      <Text light>
        Run data through Arria to reveal important insights within your Excel
        worksheet. Use our no-code, out-of-the-box narratives or develop custom
        projects using Arria NLG Studio.
      </Text>
      <Box m={3} />
      <Text light> Add value to Excel worksheets with:</Text>
      <Box m={4} />
      <ul className="plan-desc-list-excel">
        <li>Unlimited customization of narratives and analyses.</li>
        <li>Instant reporting on data across the entire workbook.</li>
        <li>Summarization of complex tables in natural language.</li>
      </ul>
      <Box m={7} />
      <Text light style={{ fontWeight: 400 }}>
        <span style={{ fontWeight: 700, fontStyle: "italic" }}>Note: </span> To
        access custom narratives, you or someone in your organization must have
        an Arria NLG Studio license and at least one Studio project published.
      </Text>
    </>
  ),
  "excel-2002": (
    <>
      <Box m={2} />
      <Text light>
        Get familiar with our no-code, out-of-the-box NLG experience in
        Microsoft Excel.
      </Text>
      <Box m={4} />
      <Text light>
        Configure Intelligent Narratives to generate insights, or view and
        dynamically filter narrative generated by custom projects created using
        Arria NLG Studio.
      </Text>
      <Box m={3} />
      <Text light>
        Add value to your Excel reporting using narratives from our analytics
        apps:
      </Text>
      <Box m={4} />

      <ul
        className={[
          "plan-desc-list-excel",
          "plan-desc-list-excel-line-height",
        ].join(" ")}
      >
        <li>Descriptive statistics</li>
        <li>Time-based variance</li>
        <li>Target-based variance</li>
        <li>Trend analysis</li>
        <li>Ranking analysis</li>
      </ul>

      <Text light>
        Enhance the charts in your spreadsheet with narratives from our visual
        apps:
      </Text>
      <ul
        className={[
          "plan-desc-list-excel",
          "plan-desc-list-excel-line-height",
        ].join(" ")}
      >
        <li>Pie chart</li>
        <li>Bar chart</li>
        <li>Line chart</li>
      </ul>
      <Text light style={{ fontWeight: 400 }}>
        <span style={{ fontWeight: 700, fontStyle: "italic" }}>Note: </span> To
        access custom narratives, you or someone in your organization must have
        an Arria NLG Studio license and at least one Studio project published.
      </Text>
    </>
  ),
  "excel-2000": (
    <>
      <Box m={5} />
      <Text light>
        A turn-key solution for public accountants providing advisory services
        to multiple clients.
      </Text>
      <Box m={5} />
      <Text light>
        Generate analysis of the three main financial statements in seconds by
        automating period-over-period consolidated reporting through Microsoft
        Excel.
      </Text>
      <Box m={2} />
      <Text light>Add value to your business by:</Text>
      <Box m={6} />
      <ul className="plan-desc-list-excel">
        <li>expanding your advisory service offering;</li>
        <li>reducing report generation time; and</li>
        <li>dedicating more time to clients.</li>
      </ul>
    </>
  ),
}

const excelCustomPlan = {
  currencyCode: "USD",
  id: "excel-2004",
  metaData: {
    category: "excel",
    displaySignupPricing: false,
    displayMyAccountPricing: true,
    displayOrder: 2,
  },
  name: "Premium Arria for Excel + Custom",
  period: 1,
  periodUnit: "month",
}

const CategoryHeader = ({ category }) => {
  const title =
    category === "bi" ? "Arria for BI plans" : "Arria for Excel plans"
  return (
    <>
      <Divider light style={{ margin: "30px 0" }} id={`plans-${category}`} />
      <Box height={30} mt="75px" mb="50px">
        <Box display="inline-block" mx={1} />
        <H4>{title}</H4>
      </Box>
    </>
  )
}

export const Plans = ({
  onSubmit = console.log,
  category = "",
  alignPlan = "center",
  fns = {},
}) => {
  const filter = getSearchParam("filter")
  const classes = useStyles()

  let res = usePlans(filter)
  const { subscriptions } = useSubscriptions()

  let plans = category ? res[category] : res.plans
  if (subscriptions) {
    const subscriptionsByPlanId = subscriptions.reduce(
      (a, x) => ({ ...a, [x.planId]: x }),
      {},
    )
    plans = plans?.map((p) => ({
      ...p,
      ...subscriptionsByPlanId[p.id],
    }))
  }
  // add excel custom plan
  if (plans && Array.isArray(plans)) {
    if (category === "excel" || filter === "excel") {
      plans.push(excelCustomPlan)
    } else if (category === "bi" || filter === "bi") {
      // plans.push(biCustomPlan)
    }
  }

  const planDisplayOrder = (a, b) =>
    a.metaData.displayOrder - b.metaData.displayOrder

  if (res.error) return <div>An error occurred</div>
  if (res.isLoading) return <CenteredProgress />
  if (plans?.length === 0) return null

  return (
    <>
      {category && !isEmpty(plans) && <CategoryHeader category={category} />}
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Grid container justify={alignPlan} spacing={6}>
            {plans?.sort(planDisplayOrder).map((pln) => (
              <Plan
                key={pln.id}
                plan={pln}
                onSubmit={onSubmit}
                fns={fns}
                hasBiSub={plans?.some(
                  (p) =>
                    p?.metaData?.category === "bi" &&
                    Boolean(p?.subscriptionId),
                )}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export const Plan = ({ plan, onSubmit, fns = {}, hasBiSub = false }) => {
  const classes = useStyles()
  const [inProgress, setInprogress] = useState(false)

  const location = useLocation()
  const isPlans = location.pathname === "/plans"

  const handleClick = () => {
    setInprogress(true)
    const req =
      plan.status === "non_renewing"
        ? fns.reactivateSubscription(plan.subscriptionId)
        : onSubmit(plan.id)

    // If a promise is returned, then wait for it before hiding spinner
    !!req?.then ? req.finally(() => setInprogress(false)) : setInprogress(false)
  }

  const words = plan.name.split(" ")

  const subscribeable = !plan.subscriptionId || plan.status === "non_renewing"
  const isExcel = plan?.metaData?.category === "excel"
  const logo = isExcel ? excelLogo : powerBiLogo
  const selfSubscribePermitted = plan?.metaData?.selfSubscribePermitted
  const isCustomPlan =
    plan.id === "excel-2004" || plan.id === "bi-apps-1002" ? true : false

  const buttonContent = inProgress ? (
    <CircularProgress size={25} />
  ) : plan.status === "non_renewing" && selfSubscribePermitted ? (
    "Resubscribe"
  ) : plan?.metaData?.group && hasBiSub && selfSubscribePermitted ? (
    "Switch to " + plan.name.split(" ")[0]
  ) : isCustomPlan || !selfSubscribePermitted ? (
    "Contact Sales"
  ) : (
    "Get started now"
  )

  const sourceLink =
    plan?.metaData?.category === "excel"
      ? "isc-excel-contact-sales-link"
      : "isc-bi-contact-sales-link"
  const contactSalesURL = `https://www.arria.com/request-volume-pricing?source=${sourceLink}`

  return (
    <Grid item className={classes.panel} align="center">
      <Box
        width={340}
        minHeight={isExcel ? 1120 : 1270}
        padding="45px 35px 48px 40px"
        position="relative"
        bgcolor="white"
        textAlign="left"
      >
        <Box minHeight={isExcel ? 171 : 250}>
          {!isPlans ? (
            <img
              src={logo}
              alt={isExcel ? "Excel Logo" : "PowerBi Logo"}
              style={{ width: "43px" }}
            />
          ) : null}
          <Box m={2} />
          <Large role="presentation" className="name1" component="span">
            {plan.id === "excel-2000"
              ? words.slice(0, 2).join(" ")
              : words.slice(0, 1)}
          </Large>
          <Large role="presentation" className="name2" component="span">
            {plan.id === "excel-2000"
              ? words.slice(2).join(" ")
              : words.slice(1).join(" ")}
          </Large>
          {!isExcel ? (
            <>
              <Box m={3} />
              <span style={{ minHeight: "45px", display: "flex" }}>
                <Text light>
                  {plan.id === "bi-apps-1001" ? (
                    <>
                      {plan.trialPeriod}-day free trial
                      <br />
                    </>
                  ) : (
                    <>
                      Guided trial <br />
                      Develop custom projects
                      <br />
                      <br />
                      Available for Microsoft Power BI, <br />
                      Tableau, MicroStrategy, Qlik Sense <br />
                      and TIBCO Spotfire.
                    </>
                  )}
                  {plan.metaData?.displayMyAccountPricing ? (
                    <>
                      ${formattedPlanPrice(plan.price)} / month / single user
                      <br />
                      <br />
                      <span>
                        Available for Microsoft Power BI, <br />
                        Tableau,MicroStrategy, Qlik Sense <br />
                        and TIBCO Spotfire.
                      </span>
                    </>
                  ) : null}
                </Text>
              </span>
            </>
          ) : null}

          {isExcel ? (
            <>
              <Box m={3} />
              <span style={{ minHeight: "85px", display: "flex" }}>
                {plan.id === "excel-2002" ? (
                  <Text light>
                    {plan.trialPeriod}-day free trial <br />$
                    {formattedPlanPrice(plan.price)} / month / single user
                    <br />
                    8 configurable narratives <br /> View custom projects
                  </Text>
                ) : plan.id === "excel-2000" ? (
                  <Text light>
                    First month free <br /> ${formattedPlanPrice(plan.price)} /
                    month / up to 3 users
                    <br />
                    $20 per additional user
                  </Text>
                ) : (
                  <Text light>
                    Guided trial
                    <br />
                    8 configurable narratives
                    <br />
                    Develop custom projects
                  </Text>
                )}
              </span>
            </>
          ) : null}
        </Box>
        <Box>
          <div
            className={isExcel ? "excel bar" : "bi bar"}
            role="presentation"
          />
          <Box m={5} />
          {descriptions[plan.id] || <Text light>{plan.description}</Text>}
          <div className={classes.cta}>
            {subscribeable ? (
              <>
                <Button
                  onClick={
                    isCustomPlan || !selfSubscribePermitted
                      ? () => fns.goToSales(plan?.metaData?.category)
                      : handleClick
                  }
                  disabled={inProgress}
                  variant="contained"
                  fullWidth
                >
                  {buttonContent}
                </Button>
                <Box m={4} />
                {!isCustomPlan ? (
                  <Link
                    href={contactSalesURL}
                    variant="body2"
                    className={classes.supportLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Volume pricing, contact Sales
                    <ChevronRight className={classes.icon} />
                  </Link>
                ) : (
                  <Box m={8} />
                )}
              </>
            ) : isEmpty(fns) ? null : (
              <>
                <Button disabled fullWidth>
                  {inProgress ? (
                    <CircularProgress size={25} />
                  ) : plan.status === "non_renewing" ? (
                    "Resubscribe"
                  ) : plan.status === "in_trial" ? (
                    "Trial Active"
                  ) : (
                    "Subscribed"
                  )}
                </Button>
                <Box m={5} />
                <Box className={classes.plainButtons}>
                  <Button
                    onClick={() =>
                      fns.goToAddLicenses(plan?.metaData?.category)
                    }
                    variant="text"
                  >
                    Add licenses
                  </Button>
                  <Button
                    onClick={() => fns.cancelSubscription(plan.subscriptionId)}
                    disabled={plan.status === "non_renewing"}
                    variant="text"
                  >
                    Cancel
                  </Button>
                </Box>
              </>
            )}
          </div>
        </Box>
      </Box>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 14,
    flexGrow: 1,
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(-3),
    },
    // backgroundColor: "rebeccapurple",
  },
  panel: {
    fontSize: theme.typography.pxToRem(theme.spacing(4)),
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(3),
    },
  },
  cta: {
    position: "absolute",
    bottom: 50,
    left: 40,
    right: 40,
  },
  statusBtn: {
    display: "block",
    textTransform: "unset",
    width: "100%",
  },
  title: {
    color: theme.palette.common.black,
    fontSize: theme.spacing(4.5),
  },
  supportLink: {
    display: "block",
    cursor: "pointer",
    textAlign: "center",
    textDecoration: "underline",
    textDecorationThickness: "1.2px",
    fontSize: ".8rem",
    fontWeight: 600,
  },
  icon: {
    verticalAlign: "middle",
    fontSize: 17,
    color: "inherit",
  },
  price: {
    margin: theme.spacing(2, 0),
  },
  plainButtons: {
    display: "flex",
    justifyContent: "space-around",
    maxWidth: "60%",
    margin: "0 auto",
    textTransform: "unset",
    "& button": {
      fontSize: ".8rem",
      fontWeight: 600,
      marginRight: theme.spacing(3),
      minWidth: "max-content",
      padding: ".5rem 0",
      textDecoration: "underline",
      textDecorationThickness: "1.2px",
      height: "unset",
      lineHeight: 0,
    },
  },
}))
