import React from "react"

import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core"
import theme from "../theme"

const Heading = (style) =>
  withStyles(() => ({
    bold: { fontWeight: 700, ...style },
    light: { fontWeight: 400, ...style },
  }))(({ classes, light = false, children, ...rest }) => (
    <Typography className={light ? classes.light : classes.bold} {...rest}>
      {children}
    </Typography>
  ))

export const H3 = Heading({
  fontSize: theme.spacing(7),
  margin: theme.spacing(3, 0, 2, 0),
})

export const H5 = Heading({
  fontSize: theme.spacing(5),
  margin: theme.spacing(3, 0, 2, 0),
})

export const H4 = Heading({
  fontSize: theme.spacing(4),
  margin: theme.spacing(3, 0, 2, 0),
})

export const Label = Heading({ fontSize: "1rem" })
export const Large = Heading({ fontSize: "1.25rem" })
export const Text = Heading({
  fontSize: "0.875rem",
  letterSpacing: "0.2px",
  lineHeight: "1.4",
})
export const Small = Heading({ fontSize: "0.820rem" })
export const TextItalic = Heading({
  fontSize: "1rem",
  fontStyle: "italic",
  fontWeight: 300,
})
export const TextItalicBold = Heading({
  fontSize: "0.815rem",
  fontStyle: "italic",
  fontWeight: 700,
})
