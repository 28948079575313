import React from "react"
import CssBaseline from "@material-ui/core/CssBaseline"
import { StylesProvider } from "@material-ui/core/styles"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { SWRConfig } from "swr"
import ReactGA from "react-ga4"

import Routes from "pages/routes"
import theme from "../theme"

import "assets/styles/App.css"
const ga4MeasurementId = process.env.REACT_APP_GA_TRACKING_ID
ReactGA.initialize(ga4MeasurementId, {
  gaOptions: { alwaysSendReferrer: true },
})
const swrOptions = {
  errorRetryCount: 3,
  refreshInterval: 0,
  revalidateOnFocus: false,
  shouldRetryOnError: true,
}
export default () => (
  <MuiThemeProvider theme={theme}>
    <SWRConfig value={swrOptions}>
      <StylesProvider injectFirst>
        <CssBaseline />
        <Routes />
      </StylesProvider>
    </SWRConfig>
  </MuiThemeProvider>
)
