import useSWR, { mutate } from "swr"
import Cookie from "js-cookie"
import jwt_decode from "jwt-decode"

const isLoggedIn = () => Boolean(Cookie.get("id_token"))
const parseJwt = (jwt) => {
  try {
    return jwt_decode(jwt)
  } catch (e) {
    return null
  }
}
const getCustomerDetailsFromToken = () => {
  const user = parseJwt(Cookie.get("id_token")) ?? {}
  return {
    firstName: user.given_name,
    lastName: user.family_name,
    email: user.email,
  }
}

const request = async (method, url, data) => {
  const options = { method }
  const token = Cookie.get("id_token")

  if (token) options.headers = { Authorization: `Bearer ${token}` }
  if (method !== "GET" && !!data) options.body = JSON.stringify(data)

  return await fetch(url, options)
    .then(async (response) => {
      const data = await response.json()
      return { status: response.status, data }
    })
    .then((result) => {
      if (result.status < 200 || result.status >= 300) throw result

      return result
    })
}

export const api = {
  get: (url) => request("GET", url),
  post: (url, data) => request("POST", url, data),
  put: (url, data) => request("PUT", url, data),
  delete: (url, data) => request("DELETE", url, data),
  patch: (url, data) => request("PATCH", url, data),
}

const baseurl = process.env.REACT_APP_BG_URL
export const usePlans = (planId) => {
  const path = "/ordering/products/list"
  const { data, error } = useSWR(`${path}?filter=${planId}`, () => {
    const url = baseurl + path
    const request = !planId
      ? api.post(url, { filter: {} })
      : api.post(url, { filter: { planId } })
    return request.then((x) => x?.data)
  })

  const { bi, excel } =
    data?.plans?.reduce(
      (a, p) => ({
        ...a,
        [p.metaData?.category]: (a[p.metaData?.category] || []).concat(p),
      }),
      {},
    ) ?? {}

  const { plans, addOns } = data || {}
  return { plans, addOns, bi, excel, isLoading: !error && !data, error }
}

export const useSubscriptions = () => {
  const path = "/ordering/subscription"
  const { data, error } = useSWR(path, () =>
    isLoggedIn()
      ? api.get(baseurl + path).then((x) => x?.data)
      : Promise.resolve([]),
  )

  return {
    subscriptions: data?.subscriptions,
    licences: data?.licences,
    isLoading: !error && !data,
    error,
  }
}

export const cancelSubscription = (subscriptionId) => {
  const url = baseurl + "/ordering/subscription/" + subscriptionId
  return api.delete(url)
}

/*export const addSubscription = (planId, cardToken) => {
  const url = baseurl + "/ordering/subscribe"
  const payload = cardToken ? { planId, cardToken } : { planId }
  return api.put(url, payload)
}*/

export const addSubscription = (payloadData) => {
  const { planId, paymentIntent } = payloadData
  const url = baseurl + "/ordering/subscribe"
  if (planId) {
    const paymentIntentId = paymentIntent?.id
    const payload = paymentIntentId
      ? { planId, paymentIntentId: paymentIntentId }
      : { planId }
    return api.put(url, payload)
  } else {
    return api.put(url, { planId: payloadData })
  }
}

export const createSubscription = (payload) => {
  const url = baseurl + "/ordering/subscribe"
  return api.post(url, payload)
}

export const createPaymentIntent = (payload) => {
  const url = baseurl + "/ordering/paymentintent"
  return api.post(url, payload)
}

export const reactivateSubscription = (subscriptionId) =>
  api
    .patch(baseurl + `/ordering/subscription/${subscriptionId}`)
    .then((res) => {
      mutate("/ordering/subscription")
      return res
    })

export const useInvoices = () => {
  const { data, error } = useSWR("/account/invoices", (path) =>
    api.get(baseurl + path).then(({ data }) => data?.invoices?.reverse() || []),
  )
  return { invoices: data, error, isLoading: !error && !data }
}

export const getPdfInvoiceUrl = (id) =>
  api
    .get(baseurl + `/account/invoices/pdf/${id}`)
    .then(({ data }) => data?.result?.download?.download_url)

export const makePayment = (id) =>
  api
    .put(baseurl + `/account/payment/paynow/${id}`)
    .then(({ data }) => console.log("pay now response: ", data))

export const useCustomer = () => {
  const customerDetails = getCustomerDetailsFromToken()
  const { data, error } = useSWR("/account/customer", (path) =>
    isLoggedIn()
      ? api.get(baseurl + path).then(({ data }) => data?.customerRecords[0])
      : Promise.resolve(),
  )

  return {
    customer: data || customerDetails,
    error,
    isLoading: !error && !data && !customerDetails,
  }
}

export const usePaymentDetails = () => {
  const { customer } = useCustomer()

  const pmtSrc = customer?.primaryPaymentSourceId

  const { data, error } = useSWR(`/account/payment/${pmtSrc}`, (path) =>
    pmtSrc
      ? api.get(baseurl + path).then(({ data }) => data)
      : Promise.resolve({}),
  )

  return { paymentDetails: data, error, isLoading: !error && !data }
}

export const updatePaymentMethod = (paymentIntentId) =>
  api
    .put(baseurl + `/account/payment`, { paymentIntentId: paymentIntentId })
    .then(() => {
      mutate("/account/customer", {})
      mutate("/account/payment", {
        card: {
          maskedNumber: "*****",
          expiryMonth: "00",
          expiryYear: "00",
        },
      })
    })

// Account services (Arria)
export const verifyEmail = (email, confirmationCode) =>
  api.patch(process.env.REACT_APP_IAM_URL + "/signup/user/verify", {
    email,
    confirmationCode,
  })

export const resendValidationCode = (email) =>
  api.put(process.env.REACT_APP_IAM_URL + "/signup/user/verify/resend", {
    email,
  })

export const getRiskLevel = (emailAddress) =>
  api
    .post(baseurl + "/ordering/qualify", { emailAddress })
    .then((res) => res.data?.rating)
